import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import { Content } from "page_components/competition"

const Competition = ({ location }) => {
  const title = "Konkurs"

  return (
    <Layout
      location={location}
      seo={{
        title,
        description: "",
      }}
    >
      <Breadcrumbs title={title} isCompetition />
      <Content />
    </Layout>
  )
}

export default Competition
